import * as types from 'modules/acquiring/actions/action-types';
import { fetchWrapper } from 'helpers';
import moment from "moment";

export const setAcquiringActiveTab = (acquiringActiveTab) => ({
  type: types.SET_ACQUIRING_ACTIVE_TAB,
  payload: {
    acquiringActiveTab,
  },
});

export const createDomainToken = (comment, domain, selfEmployed) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/domain/token/create`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        comment,
        domain,
        selfEmployed,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.CREATE_DOMAIN_TOKEN_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CREATE_DOMAIN_TOKEN_FAIL,
        });
        reject(err);
      });
  });
};

export const confirmDomainToken = (domain) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/domain/token/confirm?domain=${domain}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
    })
      .then((res) => {
        dispatch({
          type: types.CONFIRM_DOMAIN_TOKEN_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CONFIRM_DOMAIN_TOKEN_FAIL,
        });
        reject(err);
      });
  });
};

export const downloadDomainToken = (domain) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/domain/token/download?domain=${domain}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: types.DOWNLOAD_DOMAIN_TOKEN_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.DOWNLOAD_DOMAIN_TOKEN_FAIL,
        });
        reject(err);
      });
  });
};

export const setChosenPaymentWaysFilters = (chosenPaymentWaysFilters) => ({
  type: types.SET_CHOSEN_PAYMENT_WAYS_FILTERS,
  payload: {
    chosenPaymentWaysFilters,
  },
});

export const setChosenPaymentStatusFilters = (chosenPaymentStatusFilters) => ({
  type: types.SET_CHOSEN_PAYMENT_STATUS_FILTERS,
  payload: {
    chosenPaymentStatusFilters,
  },
});

export const setChosenSumFromFilters = (chosenSumFromFilters) => ({
  type: types.SET_CHOSEN_SUM_FROM_FILTERS,
  payload: {
    chosenSumFromFilters,
  },
});

export const setChosenSumUntilFilters = (chosenSumUntilFilters) => ({
  type: types.SET_CHOSEN_SUM_UNTIL_FILTERS,
  payload: {
    chosenSumUntilFilters,
  },
});

export const setSearchWord = (searchWord) => ({
  type: types.SET_SEARCH_WORD,
  payload: {
    searchWord,
  },
});

export const setSearchDatesRange = (datesRange) => ({
  type: types.SET_SEARCH_DATES_RANGE,
  payload: {
    datesRange,
  },
});

export const changeAcquiringSettings = (
  uuid,
  callbackUrl,
  connectionMethod,
  email = null,
  name,
  paymentCancelled,
  paymentErrorNotify,
  paymentRevertNotify,
  paymentSucceeded,
  redirectImmediately,
  successAndFailResultUrl,
) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/${uuid}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        callbackUrl,
        connectionMethod,
        email,
        name,
        paymentCancelled,
        paymentErrorNotify,
        paymentRevertNotify,
        paymentSucceeded,
        redirectImmediately,
        successAndFailResultUrl,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.CHANGE_ACQUIRING_SETTINGS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CHANGE_ACQUIRING_SETTINGS_FAIL,
        });
        reject(err);
      });
  });
};

export const getAcquiringSettings = (uuid) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/${uuid}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: types.GET_ACQUIRING_SETTINGS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_ACQUIRING_SETTINGS_FAIL,
        });
        reject(err);
      });
  });
};

export const updateMerchantPageStyle = (uuid, style) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/${uuid}/payment-page-style`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        style,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.UPDATE_MERCHANT_PAGE_STYLE_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.UPDATE_MERCHANT_PAGE_STYLE_FAIL,
        });
        reject(err);
      });
  });
};

export const generateMerchantApiKey = (uuid) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/${uuid}/secret-key`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then((res) => {
        dispatch({
          type: types.GENERATE_MERCHANT_API_KEY_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GENERATE_MERCHANT_API_KEY_FAIL,
        });
        reject(err);
      });
  });
};

export const setAnalyticsDatesRange = (analyticsDatesRange) => ({
  type: types.SET_ANALYTICS_DATES_RANGE,
  payload: {
    analyticsDatesRange,
  },
});

export const setIsOperationsCalendarFilterOpen = (isOperationsCalendarFilterOpen) => ({
  type: types.SET_IS_OPERATIONS_CALENDAR_FILTER_OPEN,
  payload: {
    isOperationsCalendarFilterOpen,
  },
});

export const getMerchantsList = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/list/all`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: types.GENERATE_MERCHANTS_LIST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GENERATE_MERCHANTS_LIST_FAIL,
        });
        reject(err);
      });
  });
};

const prepareStartDate = (day?: number, month?: number, year?: number) => {
  day = day ? day : 1;
  month = month ? month : moment().month();
  year = year ? year : moment().year();

  return moment(`${year}/${month}/${day}`).startOf('day').utc(true).format();
}

const prepareEndDate = (day?: number, month?: number, year?: number) => {
  year = year ? year : moment().year();
  month = month ? month : moment().month();
  day = day ? day : moment(`${year}-${month}`).endOf('month').day();

  return moment(`${year}/${month}/${day}`).endOf('day').utc(true).format();
}

export const getMerchantsHistory = ({
  allMerchants = false,
  cashSources = null,
  day = null,
  endDay = null,
  endMonth = null,
  endYear = null,
  maxSum = null,
  merchantUuid = null,
  minSum = null,
  month,
  page,
  pageSize,
  states = null,
  year,
  zoneOffset,
}) => (dispatch) => {

  let url = `${process.env.REACT_APP_API_URL}/payment/history?page=${page}&pageSize=${pageSize}`;

  if (allMerchants) {
    url += `&allMerchants=${allMerchants}`;
  }

  if (cashSources) {
    url += `&cashSources=${cashSources}`;
  }

  url += `&startDateRFC=${prepareStartDate(day, month, year)}`

  url += `&endDateRFC=${prepareEndDate(endDay, endMonth, endYear)}`

  if (maxSum) {
    url += `&maxSum=${maxSum}`;
  }

  if (merchantUuid) {
    url += `&merchantUuid=${merchantUuid}`;
  }

  if (minSum) {
    url += `&minSum=${minSum}`;
  }

  if (states) {
    url += `&states=${states}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: types.GET_MERCHANTS_OPERATIONS_LIST_SUCCESS,
          payload: {
            res,
            page,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_MERCHANTS_OPERATIONS_LIST_FAIL,
        });
        reject(err);
      });
  });
};

export const updateMerchantPaymentWays = (uuid, cashSources) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/merchant/${uuid}/cash-sources`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        cashSources,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.UPDATE_MERCHANT_PAYMENT_WAYS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.UPDATE_MERCHANT_PAYMENT_WAYS_FAIL,
        });
        reject(err);
      });
  });
};

export const setFakeOperations = (fakeOperations) => ({
  type: types.SET_FAKE_OPERATIONS,
  payload: {
    fakeOperations,
  },
});

export const setChosenShop = (chosenShop) => ({
  type: types.SET_CHOSEN_SHOP,
  payload: {
    chosenShop,
  },
});

export const setChosenShopStatus = (statusOfChoseShop) => ({
  type: types.SET_CHOSEN_SHOP_STATUS,
  payload: {
    statusOfChoseShop,
  },
});
